





























































import Vue from 'vue';
import SearchResultComponent from '@/components/search/SearchResultComponent.vue';
import { SearchResult } from '@/interfaces/searchResult';
import validators, { pidPattern } from '@/validators';
import matches from 'validator/lib/matches';
import { ENDPOINTS, get, getService } from '@/api/mainApi';
import { DepotInfo } from '@/interfaces/depot/depotInfo';
import { RequestResponse } from '@/interfaces/requestResponse';
import { Route } from 'vue-router';
import validator from 'validator';
import isNumeric = validator.isNumeric;


export default Vue.extend({
  components: {
    SearchResultComponent,
  },
  data() {
    return {
      searchMenu: false,
      searchResults: [] as SearchResult[],
      searchQuery: '',
      searching: false,
      invalidSsn: false,
      timeout: 0,
      rules: {},
    };
  },
  computed: {
    ssnMask(): string {
      return isNumeric(this.searchQuery) ? '########-####' : ""
    },
    inputRules() {
      return isNumeric(this.searchQuery) ? this.rules.pid : this.rules.required
    }
  },
  watch: {
    searchQuery(val) {
      this.search(val);
    },
  },
  created() {
    Object.assign(this.rules, validators());
  },
  methods: {
    async search(val: string) {
      this.searchMenu = false;
      this.searching = false;
      this.searchResults = [];

      // if it is an ID the query could be very short (1 char)
      let isInteger: boolean = Number.isInteger(+val);
      const minLength: number = isInteger ? 1 : 2;

      clearTimeout(this.timeout);
      if (val && val.trim().length > 0 && val.length >= minLength) {
        this.searching = true;
        const isSsn = val.length > 10 && await this.checkSsnValid(val);
        this.timeout = setTimeout(async () => {
          let response: Array<DepotInfo> = await this.getDepotInfo(val);
          if (response && response.length > 0) {
            this.searchResults = response.map(value => this.buildSearchResultFromDepotInfo(value, val, isSsn));
          } else if (isSsn) {
            // even if we have no response we might need to build something to show the user
            this.searchResults = [this.buildSearchResultFromDepotInfo(response, val, isSsn)];
          }
          this.searching = false;
          this.searchMenu = true;
        }, 600);
      }
    },
    buildSearchResultFromDepotInfo(depotInfo: DepotInfo, query: string, isSsn: boolean): SearchResult | null {
      if (isSsn && (!depotInfo || !depotInfo.id)) {
        return {
          title: query,
          subtitle: 'Ny bevakning',
          icon: 'mdi-plus-circle-outline',
          route: ({ name: 'newKeepsake', query: { ssn: query } } as Partial<Route>),
        };
      } else {
        if (depotInfo.hasKeepsakes) {
          return {
            title: (depotInfo.name ? depotInfo.name + ' | ' : "") + depotInfo.socialSecurityNumber,
            subtitle: 'Öppna depå',
            icon: 'mdi-open-in-app',
            info: 'Depå-id: ' + depotInfo.id + (!!depotInfo.legacyId ?  ' | F.d. Depå-id: ' + depotInfo.legacyId : ''),
            route: ({ name: 'depotDetails', params: { id: depotInfo.id.toString() } } as Partial<Route>),
          };
        } else {
          return {
            title: (depotInfo.name ? depotInfo.name + ' | ' : "") + depotInfo.socialSecurityNumber,
            subtitle: 'Ny bevakning',
            icon: 'mdi-plus',
            info: 'Depå-id: ' + depotInfo.id + (!!depotInfo.legacyId ?  ' | F.d. Depå-id: ' + depotInfo.legacyId : ''),
            route: ({ name: 'newKeepsake', params: { depotId: depotInfo.id.toString() } } as Partial<Route>),
          };
        }
      }
    },
    handleFocus() {
      // show results on input focus if there has previously been a search
      if (this.searchResults.length > 0) {
        setTimeout(() => {
          this.searchMenu = true;
        }, 110);
      }
    },
    async checkSsnValid(ssn: string) {
      // backend call to make sure ssn is valid
      let response: RequestResponse | null = null;
      response = await getService(ENDPOINTS.SOCIAL_SECURITY_NUMBER + '/' + ssn + '/' + ENDPOINTS.IS_VALID);
      if (response) {
        return (response.msg == 'true'); // msg is string but returns "true" or "false" in this instance
      }
      return false;
    },
    async getDepotInfo(query: string) {
      try {
        return await get(ENDPOINTS.SEARCH + '/' + ENDPOINTS.DEPOT + '/' + query);
      } catch (e) {
      } // ignore not found error
      return null;
    },
  },
});
